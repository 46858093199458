var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-account"},[_c('div',{staticClass:"header-menu va-row"},[_c('div',{staticClass:"container-dhh va-row"},[_c('div',{staticClass:"close"},[(_vm.product.isStudent)?_c('router-link',{attrs:{"to":{ name: 'student-step-2' }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"fill":"#000","fill-rule":"evenodd","stroke":"#000","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.6","d":"M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"}})])]):_vm._e()],1),_c('div',{staticClass:"flex xs12 no-padding header-item text-center"},[_c('h1',[_vm._v("Visualizar Livro")]),(!_vm.isTeacher)?_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.showSchool()}}},[_vm._v(" FINALIZAR ")]):_vm._e(),(_vm.isTeacher)?_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'grade', params: { grade: _vm.grade } }}},[_vm._v(" FINALIZAR ")]):_vm._e()],1),_c('hr')]),_c('br'),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"container-dhh va-row"},[_c('div',{staticClass:"flex xs12 no-padding text-center"},[(_vm.isLoading)?_c('div',{staticClass:"loading-box"},[_c('div',{attrs:{"id":"loading"}}),_c('small',[_vm._v("Só um segundo")]),_c('h4',[_vm._v("Estamos carregando seu livro exclusivo")])]):_vm._e(),_c('div',{class:!_vm.isLoading ? 'show-book' : 'hide-book'},[(!_vm.isMobile)?_c('div',[_c('div',{staticClass:"flipbook-viewport"},[_c('div',{staticClass:"container-book",class:_vm.isPagesNumLoaded ? 'visible' : 'invisible',style:({ 'margin-left': _vm.isCover + 'px' })},[_c('div',{attrs:{"id":"flipbook"}},[_c('div',{staticClass:"hard"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=1&w=533&v=0&title=${bookInfo.slug}',"alt":""}})]),_c('div',{staticClass:"hard front-side"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=2&w=533&v=0&title=${bookInfo.slug}',"alt":""}})]),_c('div',[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=3&w=533&v=0&title=${bookInfo.slug}',"alt":""}})]),_c('div',{staticClass:"hard back-side"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=' +
												(this.pageNum - 1) +
												'&w=533&v=0&title=${bookInfo.slug}',"alt":""}})]),_c('div',{staticClass:"hard"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=' +
												this.pageNum +
												'&w=533&v=0&title=${bookInfo.slug}',"alt":""}})])])]),_c('div',{staticClass:"controls"},[_c('button',{on:{"click":function($event){return _vm.toTheLeft()}}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('div',{staticClass:"info-space"},[_c('div',{staticClass:"current-pages"},[_c('div',{staticClass:"list",style:({ left: '-' + _vm.numberPositions + 'px' })},_vm._l((_vm.pageNum),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(index + 1))])}),0)]),_c('div',{staticClass:"info-pages"},[_vm._v(_vm._s(_vm.pageNum)+" PÁGINAS")])]),_c('button',{on:{"click":function($event){return _vm.toTheRight()}}},[_c('i',{staticClass:"fa fa-chevron-right"})])])])]):_vm._e(),(_vm.isMobile)?_c('div',[_c('div',{staticClass:"list-mobile",class:_vm.mobileStopped ? 'active' : '',attrs:{"id":"list-mobile"}},[_c('div',{staticClass:"mobile-shelf",style:({
									width: 322 * _vm.pageNum - (_vm.pageNum - 2) * 5 + 'px',
								})},_vm._l((_vm.pages),function(item,index){return _c('div',{key:index,staticClass:"mini-cover",attrs:{"id":'page-' + index}},[_c('img',{staticClass:"img-book",attrs:{"width":"100%","src":item.url,"alt":""}}),(!item.isLoaded)?_c('div',{staticClass:"loading-bg"},[_c('div',{staticClass:"loading book-loading"})]):_vm._e()])}),0)])]):_vm._e()])])]),_c('vuestic-modal',{ref:"largeModal",attrs:{"show":_vm.show,"large":true,"okText":_vm._f("translate")('modal.confirm'),"cancelText":_vm._f("translate")('modal.cancel'),"noButtons":true},on:{"update:show":function($event){_vm.show=$event}}},[_c('div',{staticClass:"flex xs12 text-center"},[_c('h1',[_vm._v("Parabéns, seu livro ficou incrível!")]),_c('p',[_vm._v(" Logo ele chegará na sua escola pra você aproveitar muito a leitura! ")]),_c('img',{staticClass:"congrats",attrs:{"src":require('@/assets/images/congrats.png'),"alt":""}}),_c('br'),_c('br'),_c('div',{staticClass:"flex xs12 no-padding-right"},[_c('button',{staticClass:"btn btn-primary right cancel",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" OK ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }