<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="container-dhh va-row">
				<div class="close">
					<router-link
						:to="{ name: 'student-step-2' }"
						v-if="product.isStudent"
					>
						<!-- <i class="fa fa-chevron-left"></i> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<path
								fill="#000"
								fill-rule="evenodd"
								stroke="#000"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.6"
								d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
							/>
						</svg>
					</router-link>

					<!-- <router-link :to="{ name: 'grade', params: { grade: product.grade} }" v-if="isTeacher">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                <path fill="#000" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"/>
            </svg>
          </router-link> -->
				</div>

				<div class="flex xs12 no-padding header-item text-center">
					<h1>Visualizar Livro</h1>

					<button class="btn" @click="showSchool()" v-if="!isTeacher">
						FINALIZAR
					</button>

					<router-link
						:to="{ name: 'grade', params: { grade: grade } }"
						v-if="isTeacher"
						class="btn"
					>
						FINALIZAR
					</router-link>

					<!-- {{product}} -->
				</div>
				<hr />
			</div>
			<br />
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex xs12 no-padding text-center">
				<div class="loading-box" v-if="isLoading">
					<div id="loading"></div>
					<small>Só um segundo</small>
					<h4>Estamos carregando seu livro exclusivo</h4>
				</div>

				<div :class="!isLoading ? 'show-book' : 'hide-book'">
					<div v-if="!isMobile">
						<div class="flipbook-viewport">
							<div
								class="container-book"
								v-bind:style="{ 'margin-left': isCover + 'px' }"
								:class="isPagesNumLoaded ? 'visible' : 'invisible'"
							>
								<div id="flipbook">
									<div class="hard">
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=1&w=533&v=0&title=${bookInfo.slug}'
											"
											alt=""
										/>
									</div>
									<div class="hard front-side">
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=2&w=533&v=0&title=${bookInfo.slug}'
											"
											alt=""
										/>
									</div>
									<div>
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=3&w=533&v=0&title=${bookInfo.slug}'
											"
											alt=""
										/>
									</div>

									<!-- Fundo -->
									<div class="hard back-side">
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=' +
												(this.pageNum - 1) +
												'&w=533&v=0&title=${bookInfo.slug}'
											"
											alt=""
										/>
									</div>
									<div class="hard">
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												this.product.builderCharacterId +
												'?page=' +
												this.pageNum +
												'&w=533&v=0&title=${bookInfo.slug}'
											"
											alt=""
										/>
									</div>
								</div>
							</div>

							<div class="controls">
								<button @click="toTheLeft()">
									<i class="fa fa-chevron-left"></i>
								</button>
								<div class="info-space">
									<div class="current-pages">
										<div
											class="list"
											v-bind:style="{ left: '-' + numberPositions + 'px' }"
										>
											<span v-for="(item, index) in pageNum" :key="index">{{
												index + 1
											}}</span>
										</div>
									</div>

									<div class="info-pages">{{ pageNum }} PÁGINAS</div>
								</div>

								<button @click="toTheRight()">
									<i class="fa fa-chevron-right"></i>
								</button>
							</div>
						</div>
					</div>

					<div v-if="isMobile">
						<div
							class="list-mobile"
							:class="mobileStopped ? 'active' : ''"
							id="list-mobile"
						>
							<div
								class="mobile-shelf"
								v-bind:style="{
									width: 322 * pageNum - (pageNum - 2) * 5 + 'px',
								}"
							>
								<div
									v-for="(item, index) in pages"
									:key="index"
									class="mini-cover"
									:id="'page-' + index"
								>
									<img class="img-book" width="100%" :src="item.url" alt="" />
									<div class="loading-bg" v-if="!item.isLoaded">
										<div class="loading book-loading"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<vuestic-modal
			:show.sync="show"
			v-bind:large="true"
			ref="largeModal"
			:okText="'modal.confirm' | translate"
			:cancelText="'modal.cancel' | translate"
			:noButtons="true"
		>
			<div class="flex xs12 text-center">
				<h1>Parabéns, seu livro ficou incrível!</h1>
				<p>
					Logo ele chegará na sua escola pra você aproveitar muito a leitura!
				</p>

				<img
					:src="require('@/assets/images/congrats.png')"
					alt
					class="congrats"
				/>
				<br /><br />

				<div class="flex xs12 no-padding-right">
					<button
						type="button"
						class="btn btn-primary right cancel"
						v-on:click="closeModal()"
					>
						OK
					</button>
				</div>
			</div>
		</vuestic-modal>
	</div>
</template>

<script>
	import turn from "services/turn.min";
	const axios = require("axios");

	$.turn = turn;

	export default {
		name: "preview",
		components: {},
		props: {
			/**
			 * Indica se o botão esta desabilitado ou não
			 */
			product: {
				type: Object,
				required: false,
			},
			isTeacher: {
				type: Boolean,
				required: false,
				default: false,
			},
			grade: {
				type: Object,
				required: false,
				default: false,
			},
		},

		mounted() {
			const self = this;

			axios
				.get(
					"https://builder.dentrodahistoria.com.br/api/products/info/" +
						this.product.builderProductId
				)
				.then(function (response) {
					// console.log(response)
					// router.push({ name: 'next-kits' })
					self.pageNum = response.data.product.pages;
					self.isPagesNumLoaded = true;

					// Verificações
					self.isMobile = self.detectSize();

					if (self.isMobile) {
						self.loadedDesktop = false;
						self.loadedMobile = true;

						self.isLoading = false;

						setTimeout(function () {
							self.$nextTick(() => {
								self.initBookMobile();
							});
						}, 2000);
					} else {
						self.loadedDesktop = true;
						self.loadedMobile = false;

						// self.isLoading = false;

						setTimeout(function () {
							self.isLoading = false;

							self.$nextTick(() => {
								self.initBook();
							});

							self.$nextTick(() => {
								if (!self.isMobile & self.loadedDesktop) {
									self.nextTicker(self);
								}
							});
						}, 2000);
					}
				})

				.catch(function (error) {
					console.log(error);
					// router.push({ name: 'next-kits' })
				});

			window.addEventListener("resize", function (e) {
				// Detectar página
				self.isMobile = self.detectSize();

				if (self.isMobile === true && self.loadedMobile === false) {
					if (self.mobilePagePosition === 0) {
						self.initBookMobile();
					}

					self.loadedDesktop = false;
					self.loadedMobile = true;
				} else {
					if (self.isMobile === false && self.loadedDesktop === true) {
						var size = self.resize();
						$("#flipbook").turn("size", size.width, size.height);
						$("#flipbook").turn("resize");
					} else if (self.isMobile === false && self.loadedDesktop === false) {
						self.loadedDesktop = true;
						self.loadedMobile = false;

						self.currentPage = 3;
						self.loadedPages = 3;
						self.numberPositions = 0;

						self.$nextTick(() => {
							self.pages = [];
							self.mobilePagePosition = 0;

							self.initBook();
						});
					}
				}
			});
		},
		updated() {
			const self = this;

			if (!self.isMobile & self.loadedDesktop) {
				self.nextTicker(self);
			}

			this.$nextTick(() => {
				if (self.isMobile & self.loadedMobile) {
					self.mobilePagePosition =
						document.getElementById("page-" + (self.loadedPages - 1))
							.offsetLeft - 50;
					document
						.getElementById("list-mobile")
						.addEventListener("scroll", self.handleScroll);
				}
			});
		},

		data() {
			return {
				hasMouse: true,
				pages: [],
				counter: 0,
				pageNum: 36,

				currentPage: 3,
				loadedPages: 3,

				numberPositions: 0,
				mobilePagePosition: 0,

				isMobile: false,
				isLoaded: false,

				isPagesNumLoaded: false,

				loadedMobile: false,
				loadedDesktop: false,

				isCover: 0,

				mobileStopped: true,

				isLoading: true,
			};
		},

		methods: {
			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}
			},

			showSchool() {
				// this.schoolModalType = type
				// this.schoolSelected = this.jsonCopy(school);
				this.$refs.largeModal.open();
			},

			nextTicker(self) {
				$("#flipbook").bind("turning", function (event, page, view) {
					self.currentPage = page;

					if (self.currentPage >= self.loadedPages - 2) {
						self.moreBooks();
					}

					if (self.currentPage === 1) {
						self.numberPositions = 0;
						self.isCover =
							"-" + $(".page-wrapper[page=" + self.currentPage + "]").width();
					} else if (self.currentPage === self.pageNum) {
						self.isCover = $(
							".page-wrapper[page=" + self.currentPage + "]"
						).width();
					} else {
						self.isCover = 0;

						if (self.currentPage % 2 == 0) {
							self.numberPositions = self.currentPage * 30;
						} else {
							self.numberPositions = (self.currentPage - 1) * 30;
						}
					}

					$(".page").unbind("click");
					$(".page").click(function (event) {
						event.preventDefault();
						event.stopPropagation();
						event.stopImmediatePropagation();

						var pagina = $(this).parent().parent().attr("page");

						if (pagina % 2 == 0) {
							$("#flipbook").turn("previous");
						} else {
							$("#flipbook").turn("next");
						}
					});

					// Exibir a abertura de página
					// if (page === 2) {
					//   setTimeout(function () {
					//     $('#flipbook').turn('peel', 'tr')
					//   }, 800)
					// }
				});
			},

			// Desk events
			initBook() {
				$("#flipbook").turn({
					width: "100vh",
					height: "calc(75vh - 50px)",
					autoCenter: true,
					gradients: true,
					acceleration: true,
					duration: 800,
					elevation: 150,
					options: { turnCorners: "null" },
				});

				this.isCover = "-" + $(".page-wrapper[page=1]").width();

				$("#flipbook").bind("start", function (event, pageObject, corner) {
					if (
						corner == "tl" ||
						corner == "tr" ||
						corner == "bl" ||
						corner == "br"
					) {
						event.preventDefault();
					}
				});

				// Habilitar passagem via o dedo
				$(document).keydown(function (e) {
					var previous = 37;
					var next = 39;
					switch (e.keyCode) {
						case previous:
							jQuery("#flipbook").turn("previous");
							break;
						case next:
							jQuery("#flipbook").turn("next");
							break;
					}
				});

				// Iniciar click nas capas (80% do tamanho)
				$(".page").click(function (event) {
					event.preventDefault();
					event.stopPropagation();
					event.stopImmediatePropagation();

					var pagina = $(this).parent().parent().attr("page");

					if (pagina % 2 == 0) {
						$("#flipbook").turn("previous");
					} else {
						$("#flipbook").turn("next");
					}
				});

				// Verificar páginas carregadas e nunca carregas 2 últimas
				for (var i = this.loadedPages; i < this.pageNum - 2; i++) {
					var pageCount = $("#flipbook").turn("pages") + 1;

					var element = $(
						'<div><img class="img-book" width="100%" src="https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/' +
							this.product.builderProductId +
							"?page=" +
							i +
							'&w=100&pw=0" alt=""><div class="loading-bg"><div class="loading book-loading"></div></div> </div>'
					);

					$("#flipbook").turn("addPage", element);
					$("#flipbook").turn("pages", pageCount); // Sets the total # of pages
				}

				$("#flipbook").turn("next");
				setTimeout(function () {
					$("#flipbook").turn("previous");
				}, 1000);
			},

			moreBooks() {
				for (var i = this.loadedPages + 1; i <= this.loadedPages + 4; i++) {
					this.addPage(i, $("#flipbook"));
				}
				this.loadedPages = this.loadedPages + 4;
			},

			addPage(page, book) {
				if (book.turn("hasPage", page) && page <= this.pageNum - 2) {
					let classHard = "";

					var element = $("<div />").html(
						'<div><img class="img-book" width="100%" src="https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/' +
							this.product.builderProductId +
							"?page=" +
							page +
							'&w=100&pw=0" alt=""><div class="loading-bg"><div class="loading book-loading"></div></div> </div>'
					);
					book.turn("addPage", element, page);

					var url =
						"https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
						this.product.builderCharacterId +
						"?page=" +
						page +
						"&w=533&v=0";

					axios
						.get(url, {
							responseType: "arraybuffer",
						})
						.then((response) => {
							const cover = new Buffer.from(response.data, "binary").toString(
								"base64"
							);
							element.html(
								"<img width='100%' src='data:image/png;base64," + cover + "'>"
							);
						});
				}
			},

			toTheLeft() {
				$("#flipbook").turn("previous");
			},

			toTheRight() {
				$("#flipbook").turn("next");
			},

			detectSize() {
				var menuStatus = false;
				if (window.innerWidth < 990) {
					menuStatus = true;
				}
				return menuStatus;
			},

			resize() {
				var barSize = $(".ddh-header").height() + 40;
				var controlSize = $(".controls").height() + 20;

				var width = "100vh";
				var height = "calc(75vh - 50px)";

				return {
					width: width,
					height: height,
				};
			},

			// Mobile events
			initBookMobile() {
				this.loadedPages = 1;

				for (var i = 0; i < this.pageNum; i++) {
					let page = {
						url:
							"https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/" +
							this.product.builderProductId +
							"?page=" +
							i +
							"&w=100&pw=0",
						isLoaded: false,
					};
					this.pages.push(page);
				}

				this.moreBooksMobile();
			},

			moreBooksMobile() {
				const self = this;

				for (
					var i = this.loadedPages;
					i <= this.loadedPages + 4 && i <= this.pageNum;
					i++
				) {
					let x = i;
					var url =
						"https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
						this.product.builderCharacterId +
						"?page=" +
						i +
						"&w=312&v=0";

					axios
						.get(url, {
							responseType: "arraybuffer",
						})
						.then((response) => {
							var responseok = response;

							const cover = new Buffer.from(response.data, "binary").toString(
								"base64"
							);
							// element.html("<img width='100%' src='data:image/png;base64," + cover + "'>")
							let page = {
								url: "data:image/png;base64," + cover,
								isLoaded: true,
							};

							self.pages.splice(x - 1, 1, page);
						});
				}

				this.loadedPages = this.loadedPages + 4;
				if (document.getElementById("page-" + this.loadedPages - 1)) {
					this.mobilePagePosition =
						document.getElementById("page-" + this.loadedPages).offsetLeft - 50;
				}
			},

			handleScroll(event) {
				if (
					document.getElementById("list-mobile").scrollLeft >=
					this.mobilePagePosition
				) {
					this.moreBooksMobile();
				}
				this.mobileStopped = false;
			},
		},
	};
</script>
<style lang="scss" scoped>
	@import "../../assets/turn/jquery.ui.css";
	@import "../../assets/turn/jquery.ui.html4.css";
	// @import '../../assets/turn/steve-jobs-html4.css';
	@import "../../assets/turn/steve-jobs.css";
	::v-deep .vuestic-modal .modal-header {
		display: none;
	}

	.congrats {
		width: auto;
	}
	.header-item {
		.btn {
			position: absolute;
			right: 15px;
			top: 25px;
		}
	}
	.show-book {
		opacity: 1 !important;
		height: auto;
	}
	.hide-book {
		opacity: 0 !important;
		height: 0px;
		overflow-y: hidden;
	}
	.loading-box {
		position: absolute;
		left: 0;
		right: 0;
		top: 35%;
		bottom: 0;
		margin-bottom: auto;
		margin-top: auto;
		z-index: 9999;

		small {
			width: 100%;
			font-size: 14px;
			font-weight: bold;
			display: block;
			font-family: "Nunito";
			margin-top: 20px;
		}

		h4 {
			font-size: 18px;
			margin-top: 10px;
			font-weight: 400;
		}
	}

	@keyframes shake {
		0% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
		10% {
			-webkit-transform: translateX(20px);
			transform: translateX(20px);
		}
		20% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
		100% {
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
	}

	@-webkit-keyframes shake {
		0% {
			-webkit-transform: translateX(0px);
		}
		40% {
			-webkit-transform: translateX(20px);
		}
		50% {
			-webkit-transform: translateX(0px);
		}
		60% {
			-webkit-transform: translateX(20px);
		}
		100% {
			-webkit-transform: translateX(0px);
		}
	}

	.container-book {
		transition: margin 700ms;
	}

	.close {
		a {
			position: absolute;
			top: 26px;
			color: #4965bc;
			opacity: 1;

			// svg{
			//     path{
			//       fill:#4965bc;
			//       stroke:#4965bc;
			//     }
			//   }

			&:hover {
				svg {
					path {
						fill: #4965bc;
						stroke: #4965bc;
					}
				}
			}

			@media (max-width: 990px) {
				top: 7px;
				left: 15px;
			}
		}
	}

	.header-menu {
		padding-bottom: 8px !important;
		border-bottom: 1px solid #dfdae4;
		margin-bottom: 0px !important;
		overflow: hidden;

		@media (max-width: 990px) {
			padding-bottom: 15px !important;
			// padding-bottom: 10px !important;
		}

		h1 {
			font-size: 24px;
			margin-bottom: 25px;
			margin-top: 40px;

			@media (max-width: 990px) {
				font-size: 20px;
				margin-top: 20px;
				margin-bottom: 0px;
			}
		}
	}

	.list-mobile {
		width: 100%;
		overflow-x: scroll;
		text-align: left;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;

		.mobile-shelf {
			margin-left: 20px;
		}

		&.active {
			-webkit-animation: shake 1.6s ease-in-out infinite;
			animation: shake 1.6s ease-in-out infinite;
		}
	}

	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	}

	.mini-cover {
		display: inline-block;
		max-width: 312px;
		position: relative;
		width: 100%;
		text-align: left;

		margin: 0px 5px;

		&:first-child {
			margin-right: 5px;
		}

		&:nth-child(odd) {
			margin-left: 0;

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-image: -webkit-gradient(
					linear,
					left top,
					right top,
					from(black),
					color-stop(7%, transparent)
				);
				background-image: linear-gradient(to right, black, transparent 7%);
				opacity: 0.15;
			}
		}
		&:nth-child(even) {
			margin-right: 0;

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-image: -webkit-gradient(
					linear,
					right top,
					left top,
					from(black),
					color-stop(7%, transparent)
				);
				background-image: linear-gradient(to left, black, transparent 7%);
				opacity: 0.15;
			}
		}

		img {
			width: 100%;
			filter: none !important;
			-webkit-filter: none !important;
			max-height: 437px;
		}
	}

	body {
		overflow-x: hidden !important;
	}
	.controls {
		margin: 0 auto;
		display: block;
		display: inline-block;
		margin-top: 20px;
		position: relative;
		z-index: 2;

		button {
			display: inline-block;
			background-color: #ffffff;
			-webkit-box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
			box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
			height: 47px;
			width: 47px;
			border-radius: 30px;
			text-align: center;
			color: #afa2ba;
			border: none;
		}

		.info-space {
			display: inline-block;
			margin: 0px 12px;
			font-size: 10px;
			font-weight: 900;
			color: #afa2ba;
			font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
			position: relative;
			top: 9px;
		}

		.current-pages {
			width: 60px;
			white-space: nowrap;
			line-height: 1;
			overflow: hidden;
			transition: all 0.5s ease;

			.list {
				position: relative;
				transition: all 0.5s ease;
			}

			span {
				width: 30px;
				display: inline-block;
				font-size: 16px;
				text-align: center;

				&:first-child {
					margin: 0px 15px;
				}

				&:last-child {
					margin: 0px 15px;
				}
			}
		}
	}

	.loading-bg {
		background-color: #fff;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		opacity: 0.65;
		filter: blur(8px);
		-webkit-filter: blur(0px);
	}
	img {
		width: 100%;
		/* Add the blur effect */
	}

	::v-deep {
		.bounding-box {
			cursor: grab !important;
		}
	}

	.action-bar {
		position: absolute;
		bottom: 0px;
		text-align: center;
		left: 0;
		right: 0;
	}

	::v-deep.container {
		max-width: 100% !important;

		@media (min-width: 1200px) {
			max-width: 100% !important;
		}

		@media (min-width: 992px) {
			max-width: 100% !important;
		}

		@media (min-width: 768px) {
			max-width: 100% !important;
		}

		@media (min-width: 576px) {
			max-width: 100% !important;
		}
	}

	.my-account {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
	}
	.flipbook {
		width: 90vw;
		height: 90vh;
		position: relative;
	}

	body {
		position: relative;
		display: flex;
		padding: 0;
		margin: 0;
		min-height: 100vh;
	}
	.book {
		height: 40vh;
		width: 90vh;
		margin: auto;
	}
	.book img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
</style>
